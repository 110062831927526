import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Icon,
    Container,
    Grid,
    Menu,    
} from 'semantic-ui-react';

import OutsideClickHandler from 'react-outside-click-handler';


export default class TopNavBar extends React.Component {

  state = {
    dropdownMenuStyle: {
      display: "none"
    }
  };


  closeDropDown = () => {    
    let newState = Object.assign({}, this.state);
    if (newState.dropdownMenuStyle.display === "flex" ) {
      newState.dropdownMenuStyle = { display: "none" };
    }
    this.setState(newState);
  };

  onClickHandler = () => {
    let newState = Object.assign({}, this.state);
    if (newState.dropdownMenuStyle.display === "none") {
      newState.dropdownMenuStyle = { display: "flex" };
    } else {
      newState.dropdownMenuStyle = { display: "none" };
    }
    this.setState(newState);
  };


    render(){
      return (
<>                     
<OutsideClickHandler
  onOutsideClick = {()=>{
    this.closeDropDown()
  }}
>
<Grid className="tablet computer only">
<Container style ={{marginBottom:'1em'}}>
  <Menu fixed="top" borderless inverted size="small"
  >
    <Menu.Item header active>
      Edgardo Santiago
    </Menu.Item>
    <Menu.Item as= { Link } to = "/">
      Home
    </Menu.Item>
    <Menu.Item as= { Link } to= "/about"> About </Menu.Item>
    <Menu.Item as={ Link } to= "/projects"> Projects </Menu.Item>
    <Menu.Item position= "right"><i class="phone icon"></i>787.910.2454 &ensp;
    <i class="envelope icon"></i>esantiago.sales@gmail.com</Menu.Item>

  </Menu>
</Container>
</Grid>
<Grid className="mobile only" style ={{marginBottom:'1em'}}>
<Menu inverted borderless size="huge" fixed="top">
  <Menu.Item  active header>
    Edgardo Santiago
  </Menu.Item>
  <Menu.Menu position="right">
    <Menu.Item>
      <Button
        icon
        basic
        inverted
        toggle
        onClick={ this.onClickHandler }
      >
        <Icon name="content" />
      </Button>
    </Menu.Item>
  </Menu.Menu>
  <Menu
    vertical
    borderless
    inverted
    fluid
    style={ this.state.dropdownMenuStyle }
  >
    <Menu.Item as={ Link } to="/" onClick={this.closeDropDown}>
      Home
    </Menu.Item>
    <Menu.Item as={Link} to="/about" onClick={this.closeDropDown}>About</Menu.Item>
    <Menu.Item as={Link} to="/projects" onClick={this.closeDropDown}>Projects</Menu.Item>
  </Menu>
</Menu>
</Grid>
</OutsideClickHandler>
</>
    )
  }
}
