import React from 'react';

import Header from './TopNavBar';
import Footer from './BottomNavBar';
import Carousel from './Carousel';

export default function Layout (props) {
    
        return (
            <div>
            <Header/>
            <Carousel/>
             {props.children}
            <Footer />
            </div>
        )
    }
