import React from 'react';
import { Container, Header, Grid, List } from 'semantic-ui-react';


function About(props) {
 
        return (
        <Container style={{ marginTop:'2em' }}>
        <div data-aos="fade-down">
            <Grid flex stackable  className="top_div">
                <Grid.Row columns={1}>
                <Grid.Column verticalAlign="middle" >
                    <Header>
                        <h1>About Me</h1>
                    </Header>
                </Grid.Column>
                </Grid.Row>
            <Grid.Row className="last_div" columns={2}>
            <Grid.Column>
            <Header>
                <h2> Web Developer </h2>
            </Header>
                <p> Having an online presence is of vital for today's commerce. You need someone who understands how a deployment cycle works from the start and is able to develop continuously. Someone who understands the upcoming trends and is able to get estimates by correctly analyzing and visualizing different kinds of data. All of this information can be later used to find patterns and trends in any desired niche.</p>
                <p> Being able to properly represent information so you can convey your ideas requires to be able to attract the attention. These are essential tools for today's web developer. </p>
            <List>
                <List.Item><List.Icon name="check" />
                <List.Content>HTML/CSS/Js</List.Content>
                </List.Item>
                <List.Item><List.Icon name="check" />
                <List.Content>Library or framework (React/Vue)</List.Content>
                </List.Item>
                <List.Item><List.Icon name="check" />
                <List.Content>Node.js</List.Content>
                </List.Item>
                <List.Item><List.Icon name="check" />
                <List.Content>Additional components (redux/react-router)</List.Content>
                </List.Item>
            </List>
            <List>
                <List.Item><List.Icon name="check" />
                <List.Content><a href="https://pastebin.com/fPp6YFkS">Twitter Bot</a></List.Content>
                </List.Item>
                <List.Item><List.Icon name="check" />
                <List.Content><a href="https://pastebin.com/qMSv7XYq">Scrapy Spider</a></List.Content>
                </List.Item>
                <List.Item><List.Icon name="check" />
                <List.Content><a href="https://pastebin.com/SnxAfxpr">Sample Bs4 Script</a></List.Content>
                </List.Item>        
            </List>
            </Grid.Column>
            <Grid.Column>
            <Header>
            <h2>
                Scraping Modules  
            </h2>
            </Header>
                <p> These are some of my favorite tools for scraping and storing web data for later visualization and analysis. We scrape data most of the time for real estate, financial and retail market analysis:</p>
                <p> Some nice tools that we use to get data that can be interpreted later.</p>
            <List>
                <List.Item>
                <List.Icon name="check" />
                <List.Content>Requests and Beautifulsoup.</List.Content>
                </List.Item>
                <List.Item>
                <List.Icon name="check" />
                <List.Content>Scrapy Modules.</List.Content>
                </List.Item>
                <List.Item>
                <List.Icon name="check" />
                <List.Content> 
                    Use logic to filter out falsy data.
                </List.Content>
                </List.Item>
                <List.Item>
                <List.Icon name="check" />
                <List.Content>
                    Then use Pandas to put into arrays. 
                </List.Content>
                </List.Item>                
            </List>
            <br/>
            <p>Please visit the social links below to get in contact or call <strong>646.334.5483</strong>. I am at your service.</p>
            </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
        </Container>
        )
    }

export default About;