import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu } from 'semantic-ui-react';

export default function BottomNavBar() {

  const [activeItem, setActiveItem] = useState({
       activeItem: " "
    })

  const handleItemClick = (e, { name }) => setActiveItem({ activeItem: name })
    return(  
        <div>
    <Menu borderless inverted size="small" fixed="bottom" widths={5}
    style={{margin:0}}>
        <Menu.Item
            position="left"
            textAlign="center"  
        >
            <h6>Made with ❤ 2020©, Edgardo Santiago <span role="img" aria-label="PR">🇵🇷</span></h6> 
        </Menu.Item>
        <Menu.Item
            href="https://www.linkedin.com/in/edgardo-santiago-73340413a/"
            target="blank"
            name="linkedin alternate"
            active={ activeItem === "linkedin alternate" }
            onClick={ handleItemClick }    
        >
            <Button
                floated= "center"
                circular
                color= "linkedin"
                size= "small" 
                icon= "linkedin alternate"
            />
        </Menu.Item>
        <Menu.Item
            href= "https://www.github.com/edgardojs"
            target= "blank" 
            name= "github alternate"
            active={ activeItem === "github alternate" }
            onClick={ handleItemClick }
        >
            <Button
                floated= "center"
                color= "github"
                circular
                size= "small" 
                icon= "github alternate" 
            />
        </Menu.Item>
        <Menu.Item
            href="https://www.facebook.com/homebusinessop/"
            target="blank"    
            name="facebook"
            active= { activeItem === "facebook" }
            onClick={ handleItemClick }                    
        >
            <Button
                floated="center"
                color="facebook"
                circular
                size="small" 
                icon="facebook" 
                />
        </Menu.Item>
        <Menu.Item
        position="right"
        >
        <Link to="/privacy">
            <h6>Privacy Policy</h6>
        </Link>
        </Menu.Item>    
        </Menu>
        </div>
    )
  }