import React from 'react';
import { Button, Container, Header, Grid, Modal, Segment, Card, Image } from 'semantic-ui-react';

import ContactForm from './ContactForm';



function Projects(props){
    
        return (
        <Container style={{ marginTop:'2em' }}>
        <div>
        <Grid stackable centered>
            <Grid.Row>
                <Grid.Column>
                <Header>
                    <h1>Online Projects</h1>
                    <h2>Some of my online work.</h2>
                </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="last_div" stretched>
                <Card.Group centered>
                <div data-aos="flip-left" class="ProjectCard">
                <Card>
                    <Card.Content>
                        <a href="https://colab.research.google.com/drive/1m44BTpmjPURvaiTlJIaxsKATL2FcxDM6">
                <Card.Header><h4>Google Colab: NYC Weather Forecast</h4></Card.Header>
                </a>
                <Image className="projectItem" href="https://colab.research.google.com/drive/1m44BTpmjPURvaiTlJIaxsKATL2FcxDM6" src= {'https://i.imgur.com/1gkBFsh.png'}  alt = ''/>
                <Card.Description>
                    <Segment>
                    <p> In this colab, an API request is made to a Free Provider and with pandas and matplotlib.</p>
                    </Segment>    
                </Card.Description>
                </Card.Content>
                </Card>
                </div>
                <div data-aos="flip-left" class="ProjectCard">
                <Card>
                    <Card.Content>
                        <a href="https://codepen.io/edgardojs/pen/dyPQPvW">
                <Card.Header><h4>Puerto Rican Flag drawn in JavaScript</h4></Card.Header>
                </a>
                <Image className="projectItem" href = "https://codepen.io/edgardojs/pen/dyPQPvW" src= {'https://i.imgur.com/3KMJtqw.png'} alt = ''/>
                <Card.Description>
                    <Segment>
                    <p>Using a canvas element, we can draw directly in a browser window. In this project, the flag of Puerto Rico is drawn with only javascript elements.</p>
                    </Segment>
                </Card.Description>                
                </Card.Content>
                </Card>
                </div>
                
                <div data-aos="flip-left" class="ProjectCard">
                <Card>
                <Card.Content>
                    <a href="https://gracious-newton-d4b156.netlify.com/">
                <Card.Header><h4>USGS map data. </h4></Card.Header></a>
                <Image className="projectItem" href="https://gracious-newton-d4b156.netlify.com/" src= {'https://i.imgur.com/Vy3bebe.png'}  alt = ''/>
                <Card.Description><Segment>
                    <p>Using fetch instructions from a third party API (USGS), the daily data is obtained and mapped on a Puerto Rico zoomed section within mapbox.</p>
                </Segment> 
                </Card.Description>                
                </Card.Content>
                </Card>
                </div>
                
                <div data-aos="flip-left" class="ProjectCard">
                <Card>
                    <Card.Content>
                        <a href="http://los-pinchos-31.000webhostapp.com/">
                <Card.Header><h4>Los Pinchos Carr. 31, Naguabo</h4></Card.Header>
                </a>
                <Image className="projectItem" href="http://los-pinchos-31.000webhostapp.com/" src= {'https://i.imgur.com/95H4uTv.png'}  alt = ''/>
                <Card.Description><Segment>
                    <p>Landing page for a local food business. Inspired by the puertorican flag, implemented with bootstrap.</p>
                </Segment> 
                </Card.Description>                
                </Card.Content>
                </Card>
                </div>
                </Card.Group>           
            </Grid.Row>
        </Grid>
        </div>
        <div className="last_div">
        <Header>
            <h4>If you want to learn more about my projects, feel free to contact me.</h4>
                <Modal trigger={
                    <Button className='ui primary button'>
                        Click Here to solve your web design issues!
                    </Button>
                }  closeIcon
                >
                    <Modal.Header align='center'>Contact Form</Modal.Header>
                    <Modal.Content align='center'>
                       <Header.Subheader>
                           Send me an Email, I can answer any questions regarding my front end development capabilities.
                           <Modal.Description>
                        Once the message is received, expect an email from me. 
                    </Modal.Description>
                       </Header.Subheader>
                    </Modal.Content>
                        <ContactForm/>    
                </Modal>
                </Header>
        </div>
        </Container>
        )
    }


export default Projects;