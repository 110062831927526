import React from 'react';
import { HashRouter as Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'


import Home from './Home';
import About from './About';
import Projects from './Projects';
import PrivacyPolicy from './PrivacyPolicy';
import Layout from './layout';
import './App.css';

function App (props) {
        return (
          <Route>
          <ScrollToTop>
          <Route>
          <Layout>
          <Switch>
          <Route exact path="/">
          <HomeComponent />
          </Route>
          <Route exact path="/about">
          <AboutComponent />
          </Route>
          <Route exact path="/privacy">
          <PrivacyComponent />
          </Route>
          <Route exact path="/projects">
          <ProjectsComponent />
          </Route>
          </Switch>
          </Layout>
          </Route>
          </ScrollToTop>
          </Route>
        )
    }

const HomeComponent = () => {
  return <div><Home/></div>
}
const AboutComponent = () => {
  return <div><About/></div>
}
const ProjectsComponent = () => {
  return <div><Projects/></div>
}
const PrivacyComponent = () => {
  return <div><PrivacyPolicy/></div>
}

export default App;