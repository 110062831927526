import React from "react";
import Slider from "react-slick";

import { Card, Icon, Header, Segment } from "semantic-ui-react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Carousel() {

    const settings = {
      dots: false,
      fade: true,
      autoplay: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 3750,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    
    return (
        <div className='ui header container' style={{ marginLeft:'0em', marginBottom:"1.2em" }}>
         <Header>
          <h1>Simple and effective website design and implementation.</h1>
          <h4>I help students and businesses succeed with code.</h4>
         </Header>
            <Slider {...settings}>
                <Segment textAlign="center"> 
                  <Header as='h2' icon>
                    <Card centered >
                    <Icon
                        name="pencil alternate" 
                        size="massive"
                        fitted
                    />
                    </Card>
                        <h3>You will learn the fundamentals of Web Development.</h3>
                      </Header>
                        <Header.Subheader>
                          <p style={{wordWrap:"break-word"}}> By drawing our components firstand, we will have an idea of what to do. </p>
                        </Header.Subheader>
                </Segment>
                <Segment textAlign="center">
                <Header as="h2" icon>
                <Card centered >
                    <Icon
                        name="mobile alternate" 
                        size="massive"
                        fitted
                        />
                </Card>
                    <h3>Mobile Aware.</h3>
                </Header>
                  <Header.Subheader>
                  <p style={{wordWrap:"break-word"}}> We can make both desktop and mobile users happy, when we develop for mobile first.</p>
                  </Header.Subheader>
                </Segment>
                <Segment textAlign="center">
                <Header as="h2" icon>
                <Card centered>
                    <Icon
                        name="code"
                        size="massive"
                        fitted
                    />
                </Card>
                  <h3>From idea, to sketch, then code and display on a page.</h3>
                </Header>
                <Header.Subheader>
                  <p style={{ wordWrap:"break-word" }}>
                    Turning ideas into visual spaces for human interaction.
                    Learn with me how to make beautiful web applications.
                  </p>
                </Header.Subheader>
                </Segment>
      </Slider>
      </div>
    );
  }




export default Carousel;