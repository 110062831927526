import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header,  Grid, List, Modal} from 'semantic-ui-react';
import ContactForm from './ContactForm';

function Home(props) {

    
        return (
            <Container style={{ marginTop:'2em' }}>
            <div>
                <Header className="top_div">
                  <h1> Edgardo Santiago </h1>
                  <h4> Web Designer and Developer</h4>
                </Header>
                <p>  My goal is to help you design and implement a full stack web application. Expect to see new projects every now and then.</p>
                <p> I want to help you with your business.</p>                
                <p> Because of the reach of mobile technologies, it is important to be conscious about media sizes when developing. A handy way to work around this is to build simple web applications that can be used on different platforms. By focusing on mobile-first development, we can tackle the vast majority of the market.</p>
                <p> Here you will find more information about me and some my work online. If you need someone able to understand different coding languages and able to quickly adapt to a production enviroment, feel free to browse around and know more about me and my work. </p>
                <h2> Helping you reach your goals. </h2>
                <h4> Let's design a plan </h4>
                <p> 
                    Having a visually outstanding website is a great place to start attracting customers. We can also use social media marketing strategies to help boost your product or service. 
                    This way, you can build a solid customer base.
                    
                </p>
                </div>
                <div  data-aos="fade-up">
                <br/>
                <Header>Visit my portfolio and check out some of my online work.</Header>
                <Button 
                secondary attached="bottom" 
                as={ Link } 
                to="/projects"
                >
                     Check out my portfolio!
                </Button>
    
                </div>   
                <Header><h4>Tailored solutions for your Web Development and Marketing needs.</h4>
                <Modal trigger={
                    <Button className='ui primary button'>
                        Let's schedule a meeting.
                    </Button>
                }  closeIcon
                >
                    <Modal.Header align='center'>Contact Form</Modal.Header>
                    <Modal.Content align='center'>
                       <Header.Subheader>
                           Send me an Email, I can answer any questions regarding my front end development capabilities.
                           <Modal.Description>
                        Once the message is received, expect an email from me. 
                    </Modal.Description>
                       </Header.Subheader>
                    </Modal.Content>
                        <ContactForm/>    
                </Modal>
                </Header>
                <div className="last_div" data-aos="fade-up">
                <Header>
                    Areas of experience:
                </Header>
                <Grid stackable columns={3} divided>
                <Grid.Column>
                <List>
                    <List.Item>
                    <List.Icon name='cubes'/>
                    <List.Content>Responsive Web Apps. </List.Content>
                    </List.Item>
                    <List.Item>
                    <List.Icon name='wordpress'/>
                    <List.Content>Wordpress Development. </List.Content>
                    </List.Item>
                    <List.Item><List.Icon name='cogs'/><List.Content>Object Oriented Programming</List.Content></List.Item>
                    </List>
                </Grid.Column>

                <Grid.Column>
                    <List>
                    <List.Item>
                    <List.Icon name='react'/>
                    <List.Content>Js/React   
                    </List.Content>
                    </List.Item>
                    <List.Item>
                    <List.Icon name='python'/>
                    <List.Content>Python/Django/Flask   
                    </List.Content>
                    </List.Item>
                    <List.Item>
                    <List.Icon name='building'/>
                    <List.Content>Real Estate Scraping with Scrapy.  
                    </List.Content>
                    </List.Item>
                    <List.Item>
                    <List.Icon name='github'/>
                    <List.Content>Github Pages.  
                    </List.Content>
                    </List.Item>
                    </List>
                </Grid.Column>
                <Grid.Column>
                <List>
                <List.Item>
                    <List.Icon name='terminal'/>
                    <List.Content>BASH commands and management. </List.Content>
                    </List.Item>
                    <List.Item><List.Icon name='crosshairs'/>
                    <List.Content>Strategies for SEO and social media marketing.</List.Content>
                    </List.Item>
                    <List.Item><List.Icon name='sitemap'/>
                    <List.Content>RESTful Apis.</List.Content>
                    </List.Item>
                </List>
                </Grid.Column>
                </Grid>
                </div>
                </Container>
        )
    }


export default Home;